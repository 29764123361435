import React from 'react'
import moment from "moment";
import 'moment/locale/sq';
import Slider from "react-slick";
import { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import userProfile from "../../../images/defaultImage.jpg"
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId
} from "../../../redux/Functions/actions";
import './timeSlots.scss';
import axios from '../../../axios';
import Loader from "../Loader/Loader";
import "./stafiKompanise.scss"
const TimeSlots = (props) => {
    const [date, setDaten] = useState();
    const [loading, setLoading] = useState(true);
    console.log("Propsat Prej Redux", props);
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [myTimeSlots, setMyTimeSlots] = useState(null);
    const [doctorId, setDoctorId] = useState([]);
    const [doctorBusyHours, setDoctorBusyHours] = useState([]);
    const [selectedDay, setSelectedDay] = useState();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(null);
    const [specialty, setSpecialty] = useState(null);
    const [duration, setDuration] = useState();
    const [workingHours1, setWorkingHours1] = useState();
    const params = useParams();
    const [comment, setComment] = useState("");
    const [toDate, settoDate] = useState();
    const [doctorInfo, setDoctorInfo] = useState({});
    const [clinicInfo, setClinicInfo] = useState({});
    const [active, setActive] = useState("active");
    const navigate = useNavigate();
    const { pid } = useParams();


    let numDays = 14;
    let initDay =
        moment().locale("sq");
    let weekdays = 'E Diel_E Hënë_E Martë_E Mërkurë_E Enjte_E Premte_E Shtunë'

    let days = [];
    days.push(initDay.format());
    for (let i = 1; i < numDays; i++) {
        let d = initDay.clone().add(i, "days");
        if (weekdays.includes(d.format("dddd"))) {
            days.push(d);
        }
    }

    const getWorkingHours = () => {
        if (doctorId != "") {

            axios
                .get(`user/terminet/getCustomerUserHoursDays/${doctorId}/${params.cid}`)
                .then((data) => {
                    setWorkingHours1(data?.data)
                    setDuration(data?.data[0]?.DefaultDuration)
                    setLoading(false);
                    console.log("duratiomn", duration)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    const getBusyHourss = async () => {
        if (date !== undefined || date != "" || date != null) {
            console.log("dateStr12", date)
            await axios
                .get(`user/terminet/getTimeslotsPerDay/${params.cid}/${doctorId}/${date}`)
                .then((data) => {
                    setDoctorBusyHours(data?.data);
                    setLoading(false);
                    console.log("busyHours", doctorBusyHours)
                })
                .catch((err) => console.log(err));
        } else {
            console.log("Spo vijne")
        }
    }

    const getClinicInfos = () => {
        if (doctorId != "") {

            axios
                .get(`user/terminet/getDoctorInfo/${params.cid}/${doctorId}`)
                .then((data) => {
                    setClinicInfo(data.data[0][1])
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const getDoctorInfos = () => {
        axios
            .get(`user/terminet/single/${params.did}/${params.cid}`)
            .then((data) => {
                setDoctorInfo(data?.data)
                setLoading(false);
                console.log("doctorInfo", data?.data[0])
                setDoctorId(data?.data[0].id)
            })
            .catch((err) => console.log(err));
    }

    useEffect(() => {
        if (date === undefined) {
            setDaten("");
        }
        else {
            getBusyHourss();
        }
    }, [])

    const selectAppointmentDay = (day, index) => {
        let selectedDayWorkingHours = workingHours1?.find((singleDay) => {
            console.log("singleDatabaze", singleDay?.DitaNr)
            console.log("single", day?.isoWeekday().toString())
            return singleDay?.DisplayOrder == day?.isoWeekday().toString();
        }
        );
        setSelectedDay(day);
        setActive("");
        setSelectedDayIndex(index);
        console.log("dayy", day)
        console.log("index", selectedDayIndex)
        setSelectedTimeSlot(null);
        setComment("");
        getBusyHourss();

        console.log("selectedDayWorkingHours", selectedDayWorkingHours)

        let calculatedTimeSlots = [];
        let calculatedTimeSlot = [];

        const dateObj = moment(day);
        const dateToStr = dateObj.format("YYYY-MM-DD");
        setDaten(dateToStr);


        const workingHoursStart = selectedDayWorkingHours?.AvailableFrom.slice(11, 16);
        const workingHoursEnd = selectedDayWorkingHours?.AvailableTo.slice(11, 16);
        const breakStart = selectedDayWorkingHours?.PauzaFrom.slice(11, 16);
        const breakEnd = selectedDayWorkingHours?.PauzaTo.slice(11, 16);
        console.log("wsd", workingHoursStart)
        let hourToStart = moment(dateToStr + " " + workingHoursStart);
        let hourToEnd = moment(dateToStr + " " + workingHoursEnd);
        let hourBreakStart = moment(dateToStr + " " + breakStart);
        let hourBreakEnd = moment(dateToStr + " " + breakEnd);
        let tempHour = hourToStart;
        let tempHours = tempHour.subtract(duration, "m")

        console.log("hourToEnd", hourToEnd)
        console.log('testim gashi', tempHour, hourToEnd, (tempHour < hourToEnd));

        while (tempHour < hourToEnd) {

            let addedHour = moment(tempHour).add(duration, "m");

            if (moment(day).isSame(moment(), "day")) {
                console.log('is the same');
                console.log("testi", moment(day))
                if (addedHour < moment()) {
                    console.log('added is the same');
                    tempHour = addedHour;
                    continue;
                }
            }

            if (addedHour.isBetween(hourBreakStart, hourBreakEnd, undefined, "[)")) {

                tempHour = addedHour;
                continue;
            }
            console.log("tempHour", (tempHour._i).slice(11, 16))
            calculatedTimeSlots.push(addedHour.format("YYYY-MM-DD HH:mm:ss"));
            calculatedTimeSlot.push(addedHour.format("YYYY-MM-DD"));
            tempHour = addedHour;

        }
        setMyTimeSlots(calculatedTimeSlots);
        console.log("data1", calculatedTimeSlots)
        console.log("tempHours", tempHours)

    };

    const setOptionalComment = (event) => {
        setComment(event.target.value);
        console.log("comment", comment)
    };
    console.log("timeslots", selectedTimeSlot)

    const submitAppointment = () => {
        let appointmentData = {
            fromDate: selectedTimeSlot
                ? selectedTimeSlot : null,
            toDate: toDate,
            pershkrimi: comment ? comment : null,
        };
        if (specialty === "non-select") {
            toast.error("Please fill all the needed forms!");
        } else if (selectedTimeSlot === null) {
            toast.error("Please select a time slot!");
        } else {
            axios
                .post(`/user/appointments/${params?.cid}/${doctorId}`, appointmentData)
                .then((data) => {
                    console.log("data,", data?.data?.response[0]?.inserted_id)
                    if (data?.data?.response[0]?.inserted_id === 0) {
                        toast.error(data?.data?.response[0]?.Porosia);

                    } else {
                        toast.success("Termini u ruajt me sukses");
                        getBusyHourss();
                        setActive("");

                        console.log("Response", comment)
                        setComment("");
                    }
                })
                .catch((err) => {
                    console.log("error", err);
                    toast.error("Termini nuk u ruajt me sukses");
                });
        }
    };
    console.log("first", doctorBusyHours)
    useEffect(() => {
        if (doctorId == undefined) {
            return null
        }
        else {
            getBusyHourss();
            getWorkingHours();
        }
    }, [selectedDayIndex])


    const settings = {
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 4,
                }
            },
        ],
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 1,
        nextArrow: <span className="calendar-next">next</span>,
        prevArrow: <span className="calendar-prev">prev</span>,
    };

    useEffect(() => {
        getDoctorInfos();
        getClinicInfos();
        getWorkingHours();
    }, [doctorId])


    if (loading === true) {
        return <Loader />
    } else
        return (
            <>
                <div className="testing">
                    <i className="goBack" aria-hidden="true" onClick={() => navigate(-1)} style={{ left: props?.menu ? '23%' : '6%' }} />
                </div>
                <div className="timeSlotsContent row" style={{ left: props?.menu ? '20%' : '0%', width: props?.menu ? "80%" : "100%" }}>
                    <h3 className="titujt">Cakto terminin</h3>
                    <div className=" maincontent" style={{ left: props?.menu ? '22.5%' : '2.5%' }}>
                        <div className="timeSlotsFixed">
                            <div className="row ">
                                <div className="col-lg-5 col-xs-12  mb-5 doctorInformations">
                                    <img
                                        src={doctorInfo[0]?.photo === "null" || doctorInfo[0]?.photo === null ? userProfile : (`${process.env.REACT_APP_UPLOADS + doctorInfo[0]?.photo}`)}
                                        alt="Logo e kompanis"
                                        width="200px"
                                        className='img-wrapper mt-4'
                                    />
                                    <h5 className='font-weight-bold'>
                                        {doctorInfo[0]?.titulli} {doctorInfo[0]?.Name} {doctorInfo[0]?.SureName}
                                    </h5>
                                    <h6 className=''> {doctorInfo[0]?.specializimi}</h6>
                                    <div className='pozita'>
                                        <p>{doctorInfo[0]?.Bio == null || doctorInfo[0]?.Bio == "null" || doctorInfo[0]?.Bio == "undefined"
                                            ? <span style={{ color: "red" }}></span> : doctorInfo[0]?.Bio}</p>
                                    </div>
                                    <div className="infos">
                                        <p className='info'>
                                            Email
                                        </p>
                                        <div className="doctor-Info">
                                            {clinicInfo?.eMail}
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <p className='info'>
                                            Numri i telefonit
                                        </p>
                                        <div className="doctor-Info">
                                            {clinicInfo?.Telefoni}
                                        </div>
                                    </div>
                                    <div className="infos">
                                        <p className='info'>
                                            Adresa
                                        </p>
                                        <div className="doctor-Info">
                                            {clinicInfo?.Adresa}
                                        </div>
                                    </div>
                                    {clinicInfo?.webSajti == "" ? " "
                                        :
                                        <div className="infos">
                                            <p className='info'>
                                                Website

                                            </p>
                                            <div className="doctor-Info">
                                                {clinicInfo?.webSajti}
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col-lg-7 col-xs-12 mb-5 appointmentInfo">
                                    <div className="book-appointment-row time-slot ">
                                        <Slider {...settings}>
                                            {days?.map((day, index) => {
                                                return (
                                                    <div
                                                        className={`appointment-day ${selectedDayIndex == index ? "selected-day" : ""
                                                            }`}
                                                        key={index}
                                                        onClick={() => selectAppointmentDay(moment(day), index)}
                                                    >
                                                        <p className='daysOfWeek'>{moment(day).format("ddd")}</p>
                                                        <h4>
                                                            {moment(day).format("DD")} {moment(day).format("MMM")}
                                                        </h4>
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                    <div className="book-appointment-row  time ">
                                        {selectedDay ? (
                                            <div className="timeslots-wrapper">
                                                <div className="timeslots">
                                                    <div className="timeSlotsContainer">
                                                        <div className="row">
                                                            {myTimeSlots &&
                                                                myTimeSlots.map((element, index) => {
                                                                    console.log("element", element, index)
                                                                    let isBlocked = false;
                                                                    doctorBusyHours.forEach((el) => {
                                                                        if (
                                                                            moment(element).isBetween(
                                                                                moment(el?.prej).utc().format("YYYY-MM-DD HH:mm:ss"),
                                                                                moment(el.deri).utc().format("YYYY-MM-DD HH:mm:ss"),
                                                                                null,
                                                                                "[)"
                                                                            )
                                                                        ) {
                                                                            isBlocked = true;
                                                                        }
                                                                    });
                                                                    return (
                                                                        <div className="col-6 col-md-3 col-lg-3 ">
                                                                            <div
                                                                                className={`timeslot ${isBlocked ? " p-2 disabled" : "p-2"
                                                                                    } ${selectedTimeSlotIndex == index
                                                                                        ? active
                                                                                        : ""
                                                                                    }`}
                                                                                defaultValue="undefined"
                                                                                onClick={() => {
                                                                                    setSelectedTimeSlot(element);
                                                                                    setActive("active");
                                                                                    settoDate(moment(element).add({ minutes: duration }).format("YYYY-MM-DD HH:mm:ss"));
                                                                                    console.log("ellll", moment(element).add({ minutes: duration }).format("YYYY-MM-DD HH:mm:ss"));
                                                                                    if (!isBlocked) {
                                                                                        setSelectedTimeSlotIndex(index);
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {moment(element).format("HH:mm")}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="aboutAppointment mb-4">
                                                    <p className='text-left'>Informata rreth terminit:</p>
                                                    <textarea
                                                        className='textareaDesign'
                                                        type="text"
                                                        value={comment}
                                                        onChange={(event) => {
                                                            setOptionalComment(event);
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className="submit-appointment"
                                                    style={{ marginTop: "30px" }}
                                                    onClick={() => submitAppointment()}
                                                >
                                                    Ruaj
                                                </button>
                                            </div>
                                        ) : (
                                            <div className="info-inner">
                                                <p>
                                                    Ju lutem të zgjedhni datën dhe orën për të caktuar termin
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TimeSlots);