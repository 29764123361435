import axios from "../../../axios";
import React, { useState, useEffect } from "react";
import Close from '../../../images/light-close.svg'
import "./editCalendar.scss";
import moment from "moment";
import { useParams } from "react-router";
import { Modal, TimePicker } from "antd";
import "antd/dist/antd.css";
import ModalPortal from "../../../portal/ModalPortal";
import { toast } from "react-toastify";

const EditCalendar = ({ doctorId, docId, start, end, role, getDataRole, getData, idForEdit, getweekCall, dataWeek, costumerId, userID, dataToEdit, isEditOpen1, handleEditClose }) => {

    const [deletModal, setOpenModal] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState();
    const [doctors, setDoctors] = useState()
    const [userID1, setUserID] = useState()
    const [userData, setUserData] = useState();
    const [docName, setDocName] = useState(docId);
    const [appointmentData, setAppointmentData] = useState();
    let data = dataToEdit?.start?.toISOString().slice(0, 10);
    const [initDate, setInitDate] = useState();
    let endDate = dataToEdit?.end?.toISOString().slice(0, 10);
    const [endDatee, setEndDatee] = useState();
    let timeStart = moment(dataToEdit?.start)?.format("HH:mm")
    const [datatimestart, setDatatimestart] = useState();
    let timeEnd = moment(dataToEdit?.end)?.format("HH:mm");
    const [datatimeEnd, setDatatimeEnd] = useState();
    const format = "HH:mm";
    let dataStartdata = initDate;
    let dataend = endDatee;
    let dataStartdatahr = datatimestart;
    let dataendhr = datatimeEnd;
    let kosova = dataStartdata + "T" + dataStartdatahr + ":00.000Z"
    let shqip = dataend + "T" + dataendhr + ":00.000Z"
    useEffect(() => {
        setDocName(docId)
        getAppointmentData();
        setOpenModal(false);
    }, [isEditOpen1])

    useEffect(() => {
        setDocName(docId)
        getAppointmentData();
    }, [docId, idForEdit])

    useEffect(() => {
        setInitDate(data);
        setEndDatee(endDate);
        setDatatimestart(timeStart);
        setDatatimeEnd(timeEnd);
    }, [isEditOpen1]);

    useEffect(() => {
        const ud = Object.assign({}, userData);
        ud.fromDate = kosova;
        ud.toDate = shqip;
        setUserData(ud);
    }, [initDate, endDatee, datatimestart, datatimeEnd])


    const toDate = (e) => {
        setInitDate(e.target.value)
        setEndDatee(e.target.value)
        setDisabled(true);
    };
    const toTime = (e) => {
        setDatatimestart(e)
        setDisabled(true);
    };
    const toEnd = (e) => {
        setDatatimeEnd(e)
        setDisabled(true);
    };
    const Pershkrimi = (e) => {
        const ud = Object.assign({}, userData);
        ud.Pershkrimi = e.target.value;
        setUserData(ud);
        setDisabled(true);
    };
    const UserFullName = (e) => {
        const ud = Object.assign({}, userData);
        ud.UserFullName = e.target.value;
        setUserData(ud);
        setDisabled(true);
    };
    const handleStatus = (e) => {
        const ud = Object.assign({}, userData);
        ud.StatusID = Number(e.target.value);
        setUserData(ud);
        setDisabled(true);
    };
    const handleDocName = (e) => {
        const ud = Object.assign({}, userData);
        ud.DoctorID = doctorId;
        setDocName(e.target.value)
        setDisabled(true);
        setUserData(ud);
    };

    console.log("userID", userID, docName, doctorId, idForEdit)

    const getDoctors = () => {
        if (role === 10) {
            axios
                .get(`/customer/appointments/getDoctorsByCustomer/${role}`)
                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        else if (role === 2) {
            axios
                .get(`/customerUser/appointments/getDoctorsByCustomer/${role}`)
                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
        else if (role === 3) {
            axios
                .get(`/receptionist/appointments/getDoctorsByCustomer/${role}`)
                .then((data) => {
                    setDoctors(data?.data);
                })
                .catch((err) => console.log(err));
        }
    }

    const getStatus = () => {
        if (role === 10) {
            axios
                .get(`customer/appointments/status`)
                .then((data) => {
                    setStatus(data?.data);
                    console.log("status", status)
                })
                .catch((err) => console.log(err));
        }
        else if (role === 2) {
            axios
                .get(`customerUser/appointments/status`)
                .then((data) => {
                    setStatus(data?.data);
                    console.log("status", status)
                })
                .catch((err) => console.log(err));
        }
        else if (role === 3) {
            axios
                .get(`receptionist/appointments/status`)
                .then((data) => {
                    setStatus(data?.data);
                    console.log("status", status)
                })
                .catch((err) => console.log(err));
        }
    }
    const deleteAppointment = (e) => {
        e.preventDefault();
        if (role === 10) {
            axios
                .delete(`customer/appointments/${idForEdit}/${docName}`)
                .then((data) => {
                    setDisabled(false)
                    handleEditClose();
                    toast.success("Termini u fshi me sukses");
                    if (dataWeek) {
                        getweekCall(docName, dataWeek);
                    } else {
                        getData(docName);
                    }
                })
                .catch((err) => toast.error("Termini nuk u fshi"));
        }
        else if (role === 2) {
            axios
                .delete(`customerUser/appointments/${idForEdit}/${userID}`)
                .then((data) => {
                    setDisabled(false)
                    handleEditClose();
                    toast.success("Termini u fshi me sukses");
                    if (dataWeek) {
                        getweekCall(userID, dataWeek);
                    } else {
                        getData(userID);
                    }
                })
                .catch((err) => toast.error("Termini nuk u fshi"));
        }
        else if (role === 3) {
            axios
                .delete(`receptionist/appointments/${idForEdit}/${docName}`)
                .then((data) => {
                    setDisabled(false)
                    handleEditClose();
                    toast.success("Termini u fshi me sukses");
                    if (dataWeek) {
                        getweekCall(docName, dataWeek);
                    } else {
                        getData(docName);
                    }
                })
                .catch((err) => toast.error("Termini nuk u fshi"));
        }
    }
    console.log('appointmentDataappointmentData', appointmentData)

    let doctorPortalID = docId !== docName && docName !== undefined ? docName : docId
    const getAppointmentData = () => {
        if (role === 10) {

            axios
                .get(`customer/appointments/single/${idForEdit}/${doctorPortalID}`)
                .then((data) => {
                    setAppointmentData(data?.data[0]);
                    setUserID(data?.data[0]?.UserID);
                    console.log("UserID1", data?.data[0]?.UserID)
                    const ud = Object.assign({}, userData);
                    ud.Pershkrimi = data?.data[0].Pershkrimi;
                    ud.UserFullName = data?.data[0].UserFullName;
                    ud.fromDate = data?.data[0].FromDate;
                    ud.toDate = data?.data[0].ToDate;
                    ud.StatusID = data?.data[0].StatusID;
                    ud.DoctorID = doctorId;
                    setUserData(ud);
                })
                .catch((err) => console.log(err));
        }

        else if (role === 2) {


            axios
                .get(`customerUser/appointments/single/${idForEdit}/${userID}`)
                .then((data) => {
                    setAppointmentData(data?.data[0]);
                    setUserID(data?.data[0]?.UserID);
                    console.log("UserID1", userID1)
                    const ud = Object.assign({}, userData);
                    ud.Pershkrimi = data?.data[0].Pershkrimi;
                    ud.UserFullName = data?.data[0].UserFullName;
                    ud.fromDate = data?.data[0].FromDate;
                    ud.toDate = data?.data[0].ToDate;
                    ud.StatusID = data?.data[0].StatusID;
                    ud.DoctorID = doctorId;
                    setUserData(ud);
                })
                .catch((err) => console.log(err));
        }
        else if (role === 3) {
            axios
                .get(`receptionist/appointments/single/${idForEdit}/${doctorPortalID}`)
                .then((data) => {
                    setAppointmentData(data?.data[0]);
                    setUserID(data?.data[0]?.UserID);
                    console.log("UserID", userID1)
                    const ud = Object.assign({}, userData);
                    ud.Pershkrimi = data?.data[0].Pershkrimi;
                    ud.UserFullName = data?.data[0].UserFullName;
                    ud.fromDate = data?.data[0].FromDate;
                    ud.toDate = data?.data[0].ToDate;
                    ud.StatusID = data?.data[0].StatusID;
                    ud.DoctorID = doctorId;
                    setUserData(ud);
                })
                .catch((err) => console.log(err));
        }


    }
    console.log("appointmentData", appointmentData)

    useEffect(() => {
        getAppointmentData();
        getStatus();
        getDoctors();
    }, [])


    const submitData = (e) => {
        e.preventDefault();
        if (role === 10) {
            axios
                .put(`customer/appointments/doctor/${idForEdit}/${docName}`, userData)
                .then((res) => {
                    console.log("res", res)
                    setDisabled(false)
                    handleEditClose();
                    // getData(doctorPortalID);
                    toast.success("Termini u ndryshua me sukses");
                    if (dataWeek) {

                        getweekCall(docId, dataWeek);
                    } else {
                        getData(docId);

                    }
                })
                .catch((err) => {
                    toast.error("Termini nuk u ndryshua me sukses");
                });
        }
        else if (role === 2) {
            axios
                .put(`customerUser/appointments/doctor/${idForEdit}/${userID}`, userData)
                .then((res) => {
                    console.log("res", res)
                    setDisabled(false)
                    handleEditClose();
                    toast.success("Termini u ndryshua me sukses");
                    if (dataWeek) {

                        getweekCall(docName, dataWeek);
                    } else {
                        getData(docName);

                    }
                })
                .catch((err) => {
                    toast.error("Termini nuk u ndryshua me sukses");
                });
        }
        else if (role === 3) {
            axios
                .put(`receptionist/appointments/doctor/${idForEdit}/${docName}`, userData)
                .then((res) => {
                    console.log("res", res)
                    setDisabled(false)
                    handleEditClose();
                    toast.success("Termini u ndryshua me sukses");
                    if (dataWeek) {

                        getweekCall(docId, dataWeek);
                    } else {
                        getData(docId);

                    }
                })
                .catch((err) => {
                    toast.error("Termini nuk u ndryshua me sukses");
                });
        }
    };

    if (isEditOpen1) {
        return (
            <ModalPortal wrapperId="EditCalendarModal" >
                < div className="wholePage" onClick={() => handleEditClose()}></div >
                <div className='formEdit'>
                    <img src={Close} onClick={() => handleEditClose()} className='closeModal' />
                    <div className='editModal-Calendar'>
                        <div className="editCalendarWraper p-0 mt-5">
                            <form onSubmit={submitData} >
                                <div className="form-input">
                                    {role === 2 ? "" :
                                        <select className='selectCustomerEditCalendar col-12 mb-2' onChange={handleDocName} required title="This field is required" >
                                            <option value="" selected hidden>Ju lutem zgjedhni</option>
                                            <option disabled>Ju lutem zgjedhni</option>
                                            {doctors?.map((el) => {
                                                return (
                                                    <option
                                                        value={el.id}
                                                        selected={
                                                            Number(doctorId) ===
                                                                el?.id
                                                                ? "selected"
                                                                : false
                                                        } className="options">
                                                        {el?.docName}
                                                    </option>
                                                );
                                            })}
                                        </select>}
                                    <h6>Statusi i Terminit</h6>
                                    <select className='selectCustomerEditCalendar mb-2' onChange={handleStatus} required title="This field is required" >
                                        <option value="" selected hidden>Ju lutem zgjedhni statusin </option>
                                        <option disabled>Ju lutem zgjedhni statusin </option>
                                        {status?.map((el) => {
                                            { console.log("status", el) }
                                            { console.log("el.IDappointmentData", el.ID, el?.Pershkrimi, appointmentData?.StatusID) }
                                            return (
                                                <option
                                                    value={el.ID}
                                                    selected={
                                                        Number(appointmentData?.StatusID) ===
                                                            el?.ID
                                                            ? "selected"
                                                            : false
                                                    }
                                                    className="options">
                                                    {el?.Pershkrimi}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <h6>Rezervuesi</h6>
                                    {userID1 > 0 ?
                                        <input type="text" name="name" id="name" className="form-control mb-3" defaultValue={appointmentData?.UserFullName} onChange={UserFullName} placeholder="Emri i rezervuesit" disabled /> :
                                        <input type="text" name="name" id="name" className="form-control mb-3" defaultValue={appointmentData?.UserFullName} onChange={UserFullName} placeholder="Emri i rezervuesit" />}
                                    <h6>Pershkrimi i Terminit</h6>
                                    <input type="text" name="name" id="name" className="form-control mb-3" required placeholder="Pershkrim per terminin" defaultValue={appointmentData?.Pershkrimi} onChange={Pershkrimi} />
                                    <h6>Data e Terminit</h6>
                                    <input type="date" name="name" id="name" className="form-control col-12 mb-3" onChange={toDate} defaultValue={dataStartdata} />
                                    <h6>Orari i terminit</h6>
                                    <div className="row mx-auto">
                                        <TimePicker
                                            className="modal-ant-picker form-control col-12 col-lg-4 mb-3 mr-2"
                                            defaultValue={moment(`${dataStartdatahr}`, format)}
                                            format={format}
                                            popupStyle={{ zIndex: "999999" }}
                                            popupClassName={"selectDate"}
                                            showSecond={false}
                                            onChange={(e, i) => toTime(i)}
                                            minuteStep={5}
                                        />
                                        <TimePicker
                                            className="modal-ant-picker form-control col-12 col-lg-4 mb-3 mr-2"
                                            format={format}
                                            popupStyle={{ zIndex: "999999" }}
                                            popupClassName={"selectDate"}
                                            defaultValue={moment(`${dataendhr}`, format)}
                                            onChange={(e, i) => toEnd(i)}
                                            minuteStep={5}
                                        />
                                        <div className="col-12 col-lg-3 butoniDelete ">
                                            <strong type="submit"
                                                className="text-danger mt-2 " onClick={() => setOpenModal(true)} >
                                                Fshije
                                                <i className="fa fa-trash ml-3 "></i>
                                            </strong>
                                            {
                                                deletModal ?
                                                    <>
                                                        <div className="wholePage" onClick={() => setOpenModal(false)}></div>
                                                        <div className='deleteModalAppointment pt-3 pb-5'>
                                                            <div className='deleteModal-wrapper'>
                                                                <h5 className="text-danger text-center">A jeni te sigurt qe deshironi ta fshini kete termin</h5>
                                                                <div className="deleteContent p-0 mt-5">
                                                                    <div className="row">
                                                                        <div className="col-12 col-lg-6">
                                                                            <button className="col-12 button_delete_Po btn btn-danger" onClick={deleteAppointment}>Po</button>
                                                                        </div>
                                                                        <div className=" col-12 col-lg-6">
                                                                            <button className=" col-12 button_delete_Jo btn btn-primary" onClick={() => setOpenModal(false)}>Jo
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit"
                                            disabled={!disabled}
                                            className="button_Edit_Calendar btn "  >
                                            Ruaj
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ModalPortal >

        )
    } else return null
}
export default EditCalendar;