import React, { useState, useEffect, useRef } from "react";
import "./stock.scss";
// import doctorImage from "../images/dI.jpg";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../../axios";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
} from "../../../redux/Functions/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Loader from "../Loader/Loader";

// import sign from "jwt-encode";
// import jwt_decode from "jwt-decode"



const EditProfile = (props) => {
    console.log('shabaniasd', props)
    const [userData, setUserData] = useState([]);
    const [gender, setGender] = useState(null);
    const [profesionalInfo, setProfessionalInfo] = useState([]);
    const [passwordChange, setPasswordChange] = useState();
    const [disabled, setDisabled] = useState(false);
    const [disabledPass, setDisabledPass] = useState(false);
    const [isPrevious, setPrevious] = useState(false);
    const [loading, setLoading] = useState(true);


   


    console.log("Propss", props)
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);

    const hidePassword1 = () => {
        setShow1(!show1);
    }
    const hidePassword2 = () => {
        setShow2(!show2);
    }
    const hidePassword3 = () => {
        setShow3(!show3);
    }

    // const [register, setUserData] = useState([]);
    // const [birthday, setBirthday] = useState(null);
    const [birthday, setBirthday] = useState(new Date());
    // const navigate = useNavigate();



    const name = (e) => {
        const ud = Object.assign({}, userData);
        ud.name = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };
    const surname = (e) => {
        const ud = Object.assign({}, userData);
        ud.surename = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };
    const parentName = (e) => {
        const ud = Object.assign({}, userData);
        ud.parentName = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };

    const emergencyContact = (e) => {
        const ud = Object.assign({}, userData);
        ud.emergencyContact = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };

    const email = (e) => {
        const ud = Object.assign({}, userData);
        ud.email = e.target.value;
        setUserData(ud);
        setDisabled(true);


    };
    const password = (e) => {
        const ud = Object.assign({}, userData);
        ud.pass = e.target.value;
        setUserData(ud);
        setDisabledPass(true);

    }
    const repetPassword = (e) => {
        const ud = Object.assign({}, userData);
        ud.repetPassword = e.target.value;
        setUserData(ud);
        setDisabledPass(true);

    }
    const phone = (e) => {
        const ud = Object.assign({}, userData);
        ud.phone = e.target.value;
        setUserData(ud);
        setDisabled(true);

    };
    const checkboxHandler = (e) => {
        const ud = Object.assign({}, userData);
        ud.gender = Number(e.target.value);
        setUserData(ud);
        setDisabled(true);


    };
    // const birthdate = (e) => {
    //     let myDOB = e;
    //     myDOB.setDate(myDOB.getDate());
    //     const ud = Object.assign({}, userData);
    //     ud.dob = myDOB.toISOString();
    //     setUserData(ud);
    //     setBirthday(e);
    //     setDisabled(true);

    // };
    const birthdate = (event) => {
        // console.log("DATA MAN", event);
        setUserData({ ...userData, dob: event });
        setBirthday(event);
        setDisabled(true);
    };
    useEffect(() => {

        if (new Date(userData?.dob) != "Invalid Date") {

            setBirthday(new Date(userData?.dob));

        }

    }, [userData]);

    const getUserData = () => {
        // axios.get(`/user/` + props?.user_id).then((data) => {
        if (props?.role === 10) {
            axios.get(`customer/profile`).then((data) => {
                let incoming = data.data;
                // const ud = Object.assign({}, incoming);
                setUserData(data?.data?.response);
                setGender(data?.data?.response?.gender);
                setLoading(false);

                console.log("userData", data?.data?.response?.gender)
            }).catch((err) => {
                console.log(err)
            })
        }
        else if (props?.role === 2) {
            axios.get(`customerUser/profile`).then((data) => {
                let incoming = data.data;
                // const ud = Object.assign({}, incoming);
                setUserData(data?.data?.response);
                setGender(data?.data?.response?.gender);
                setLoading(false);

                console.log("userData", data?.data?.response?.gender)
            }).catch((err) => {
                console.log(err)
            })
        }
        else if (props?.role === 3) {
            axios.get(`receptionist/profile`).then((data) => {
                let incoming = data.data;
                // const ud = Object.assign({}, incoming);
                setUserData(data?.data?.response);
                setGender(data?.data?.response?.gender);
                setLoading(false);

                console.log("userData", data?.data?.response?.gender)
            }).catch((err) => {
                console.log(err)
            })
        }
        else {
            axios.get(`user/profile`).then((data) => {
                let incoming = data.data;
                // const ud = Object.assign({}, incoming);
                setUserData(data?.data?.response);
                setGender(data?.data?.response?.gender);
                setLoading(false);

                console.log("userData", data?.data?.response?.gender)
            }).catch((err) => {
                console.log(err)
            })
        }

    };


    useEffect(() => {
        getUserData();
    }, []);



    const submitUserData = (event) => {
        event.preventDefault()
        let profileEditData = {
            name: userData.name,
            surename: userData.surename,
            parentName: userData.parentName,
            emergencyContact: userData.emergencyContact,
            email: userData.email,
            phone: userData.phone,
            gender: userData.gender,
            dob: userData.dob,
            pass: userData.pass
        }
        console.log('profileEditData', userData)
        // axios.put(`user/update/${props.user_id}`, profileEditData)
        if (props?.role === 10) {
            axios.put(`customer/profile`, profileEditData)
                .then(data => {
                    setDisabled(false)
                    toast.success("Të dhenat u ndryshuan me sukses!");
                    setProfessionalInfo(data?.data)

                    // console.log('a po shkojn', data.data);

                }).catch(err => {
                    console.log("erroir", err);
                    toast.error(err)
                });
        }
        else if (props?.role === 2) {
            axios.put(`customerUser/profile/`, profileEditData)
                .then(data => {
                    setDisabled(false)
                    toast.success("Të dhenat u ndryshuan me sukses!");
                    setProfessionalInfo(data?.data)

                    // console.log('a po shkojn', data.data);

                }).catch(err => {
                    console.log("erroir", err);
                    toast.error(err)
                });
        }
        else if (props.role === 3) {
            axios.put(`receptionist/profile/`, profileEditData)
                .then(data => {
                    setDisabled(false)
                    toast.success("Të dhenat u ndryshuan me sukses!");
                    setProfessionalInfo(data?.data)

                    // console.log('a po shkojn', data.data);

                }).catch(err => {
                    console.log("erroir", err);
                    toast.error(err)
                });
        }
        else {
            axios.put(`user/profile/`, profileEditData)
                .then(data => {
                    setDisabled(false)
                    toast.success("Të dhenat u ndryshuan me sukses!");
                    setProfessionalInfo(data?.data)

                    // console.log('a po shkojn', data.data);

                }).catch(err => {
                    console.log("erroir", err);
                    toast.error(err)
                });
        }
        console.log('idrizi', userData)
    }
    const handleChangePassword = async (e) => {
        e.preventDefault();
        const passwordat = {
            oldPass: e?.target.passwordOld.value,
            pass: e?.target.newPassword.value,
            pasiperseritur: e.target.repetPassword.value,
        };
        console.log("testRpet", passwordat.pasiperseritur)
        console.log("testPasssssss", passwordat.pass)
        setPasswordChange(passwordat);
        if (passwordat.pass != passwordat.pasiperseritur) {
            toast.error("shaban a po ban")
        } else {

            // axios.put(`/user/changePassword/${props.user_id}`, passwordat)
            if (props?.role === 10) {
                axios.put(`customer/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Your password has been changed ", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
            else if (props?.role === 2) {
                axios.put(`customerUser/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Your password has been changed ", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
            else if (props?.role === 3) {
                axios.put(`receptionist/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Your password has been changed ", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
            else {
                axios.put(`user/profile/changePassword`, passwordat)
                    .then((res) => {

                        console.log("pe kqyri", res)
                        if (res.data === "Please enter correct old password") {
                            toast.error(`${res.data}`, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });

                        } else if (res.data !== "Please enter correct old password") {
                            toast.success("Your password has been changed ", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                            });
                            // window.location = "/profile";
                            console.log("hahhahah", res)
                        }
                        setDisabledPass(false);
                    })
                    .catch((err) => {
                        console.log("error incoming", err);
                    });
            }
        }
    }

    if (loading === true) {
        return <Loader/>
        } else
    return (
        <>
        <div className="editMyProfile" style={{ left: props?.menu ? '21%' : '30px', width: props?.menu ? "76%" : "90%" }}>
                <h3 class="titujt ">Ndrysho Profilin</h3>
                {/* <div className="registerStatic"> */}
                    <div className='terminetN' >
                        <div className="btnActive" >
                            <button className={isPrevious === false && 'active'} onClick={() => setPrevious(false)}>Ndrysho të dhënat </button>
                            <button className={isPrevious === true && 'active'} onClick={() => setPrevious(true)}>Ndrysho fjalëkalimin</button>
                        </div>

                    </div>
                    {isPrevious === false && <>
                        <div className="editProfileFixed" style={{ left: props?.menu ? '21%' : '38px', width: props?.menu ? "80%" : "100%" }}>
                            <form onSubmit={submitUserData} >

                                <div className="form-input">
                                    <div className="row">
                                        <div className="col-lg-6 col-12">
                                            <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                                Emri
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Emri"
                                                    defaultValue={userData?.name}
                                                    onChange={name}
                                                />
                                            </label>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                                Mbiemer
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Mbiemri"
                                                    defaultValue={userData?.surename}
                                                    onChange={surname}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="check_box ">
                                        <div className="radio-wrapper">
                                            <p className="text-left  pe-4 text-dark mr-2">
                                                Gjinia:
                                            </p>
                                            <div>
                                                <input
                                                    onClick={() => {
                                                        setGender(1);
                                                        Object.assign(userData, { gender: 1 });
                                                    }}
                                                    checked={gender === 1 ? true : false}
                                                    type="radio"
                                                    name="myGroupName"
                                                    id="male"
                                                    defaultValue={gender}
                                                    className={`"kushtet  ${gender === 1 ? "active" : ""
                                                        }`}
                                                />
                                                <label id="kushtet" for="male">
                                                    {"Mashkull"}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    onClick={() => {
                                                        setGender(2);
                                                        Object.assign(userData, { gender: 2 });
                                                    }}
                                                    checked={gender === 2 ? true : false}
                                                    type="radio"
                                                    id="female"
                                                    name="myGroupName"
                                                    defaultValue={gender}
                                                    className={`"kushtet"  ${gender === 2 ? "active" : ""
                                                        }`}
                                                />
                                                <label id="kushtet" for="female">
                                                    {"Femër"}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    onClick={() => {
                                                        setGender(3);
                                                        Object.assign(userData, { gender: 3 });
                                                    }}
                                                    checked={gender === 3 ? true : false}
                                                    type="radio"
                                                    id="divers"
                                                    name="myGroupName"
                                                    defaultValue={gender}
                                                    className={`"kushtet"  ${gender === 3 ? "active" : ""
                                                        }`}
                                                />
                                                <label id="kushtet" for="divers">
                                                    {"Tjeter"}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left ">
                                        E-mail:
                                        <input
                                            className="form-control emailiHover"
                                            type="email"
                                            placeholder={"Email"}
                                            defaultValue={userData?.email}
                                            onChange={email}
                                            disabled
                                        />
                                    </label>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        Numri telefonit
                                        <input
                                            type="text"
                                            className="form-control xona20"
                                            placeholder={"Numri i telefonit"}
                                            value={userData?.phone}
                                            defaultValue={userData?.phone}
                                            onChange={phone}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key) || (userData?.phone?.length > 8)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </label>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        Ditelindja
                                        {/* <DatePicker
                                        selected={birthday}
                                        value={moment(userData?.dob).format('DD/MM/YYYY')}
                                        onChange={birthdate}
                                        required
                                        className="form-control mb-2"
                                        dateFormat="yyyy-MM-dd"
                                    /> */}
                                        <DatePicker
                                            onChange={birthdate}
                                            selected={birthday}
                                            required
                                            className="form-control mb-2"
                                            dateFormat="yyyy-MM-dd"
                                            showYearDropdown
                                            dateFormatCalendar="MMMM"
                                            yearDropdownItemNumber={15}
                                            scrollableYearDropdown
                                            scrollableMonthDropdown
                                            showMonthDropdown

                                        />
                                    </label>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        Kontakti emergjent
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={"emergencyContact"}
                                            value={userData?.emergencyContact}
                                            defaultValue={userData?.emergencyContact}
                                            onChange={emergencyContact}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key) || (userData?.emergencyContact?.length > 8)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </label>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        Emri i Prindit
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder={"Parent Name"}
                                            defaultValue={userData?.parentName}
                                            onChange={parentName}
                                        />
                                    </label>
                                    <div className="form-group">
                                        <button type="submit" disabled={!disabled} className="form-control  btn btnLogin submit">Ruaj</button>
                                    </div>

                                </div>
                            </form >
                        </div ></>}
                    {isPrevious === true && <>
                        <div className="register-form2">
                            <form onSubmit={handleChangePassword}>
                                <div className="form-input">
                                    <h4 className="text-left mb-4">Ndrysho Fjalëkalimi!</h4>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        <span>Fjalëkalimi juaj i vjeter</span>
                                        <input
                                            onChange={password}
                                            placeholder="Fjalëkalimi juaj i vjeter"
                                            type={!show1 ? "password" : "text"}
                                            name="passwordOld"
                                            id="passwordOld"
                                            className="form-control2"
                                            />
                                              <span
                                            toggle="#password-field"
                                             className={show1 ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                          onClick={hidePassword1} />
                                    </label>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        <span> Fjalëkalimi juaj i ri</span>
                                        <input
                                            type={!show2 ? "password" : "text"}
                                            placeholder="Fjalëkalimi juaj i ri"s
                                            name="newPassword"
                                            id="newPassword"
                                            className="form-control2" />
                                             <span
                                            toggle="#password-field"
                                             className={show2 ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                          onClick={hidePassword2} />
                                    </label>
                                    <label htmlFor="phone" id="kushtet" className="kushtet text-left">
                                        <span>Perseritni fjalëkalimin tuaj të ri</span>
                                        <input
                                            type={!show3 ? "password" : "text"}
                                            placeholder="Perseritni fjalëkalimin tuaj"
                                            name="repetPassword" 
                                            id="repetPassword"
                                            className="form-control2" />
                                             <span
                                            toggle="#password-field"
                                             className={show3 ? "fa fa-fw fa-eye field-icon toggle-password" : "fa fa-fw fa-eye-slash field-icon toggle-password"}
                                          onClick={hidePassword3} />
                                    </label>
                                </div>
                                <div className="form-group">
                                    <button type="submit" disabled={!disabledPass} className="form-control  btn btnLogin submit">Ruaj</button>
                                </div>

                            </form>
                        </div>
                    </>}
                {/* </div> */}
                {/* </div> */}
        </div>
        </>
    );
};
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        role: state.data.role
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu())
        // loadingOff: () => dispatch(loadingOff()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
// export default Stock;