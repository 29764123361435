import React, { useEffect, useState } from "react";
import './terminet.scss';
import axios from '../../../axios';
import { render } from '@testing-library/react';
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import {
    loggMeIN,
    rmAuth,
    toggleMenu
} from "./../../../redux/Functions/actions";
import Search from "../Search/search";
import ICalendarLink from "react-icalendar-link";
import ReactTooltip from 'react-tooltip';
import { Link } from "react-router-dom";
import AnuloTerminin from "./anuloTerminin";
import Loader from "../Loader/Loader";


const Terminet = (props) => {
    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(true);
    const [appointments, setappointments] = useState([]);
    const [isPrevious, setPrevious] = useState(false);
    const [previousAppoinements, setpreviousAppoinements] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filteredPrev, setFilteredPrev] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageNumber1, setPageNumber1] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isAddOpen, setIsAddOpen] = useState(false);
    const [id, setId] = useState();
    const [dataToModal, setDataToModal] = useState(null);

    const [resetComponent, setResetComponent] = useState(0);

    const productsPerPage = 20;

    const pagesVisited = pageNumber * productsPerPage;
    const pagesVisited1 = pageNumber1 * productsPerPage;
    let displayFilteredData = [];
    const pageCount = Math.ceil(filtered.length / productsPerPage);
    const pageCountPrev = Math.ceil(filteredPrev.length / productsPerPage);

    const changePage = ({ selected }) => {
        if (isPrevious) {
            setPageNumber1(selected);
        } else {
            setPageNumber(selected);
        }
    };



    const getData = () => {
        // axios.get('/profile/appointments/' + props.user_id).then(
        axios.get('/user/appointments/upcoming').then(
            data => {
                console.log('appointments', data.data);
                setappointments(data.data);
                setFiltered(data?.data)
                setLoading(false);
            }
        ).catch(err => { console.log('Errori', err) });
    }
    const getPreviousAppointments = () => {
        // axios.get('/profile/prev-appointments/' + props.user_id).then(
        axios.get('/user/appointments/previous').then(
            data => {
                //  console.log('appointments',data.data);
                setpreviousAppoinements(data.data);
                setLoading(false);
                setFilteredPrev(data?.data)
                setLoading(false);

            }
        ).catch(err => { console.log('Errori', err) });
    }


    const handleAnuloTerminin = (data) => {
        setIsOpen(true);
        console.log("data", data)
        setDataToModal(data);
    }

    let auth = window.localStorage.getItem('terminettoken');
    useEffect(() => {
        if (auth !== null || auth !== undefined) {
            getData();
            getPreviousAppointments();
        }
    }, []);

    useEffect(() => {
        setResetComponent(Math.random());
        changePage({ selected: 0 });
    }, [isPrevious])


    if (loading === true) {
        return <Loader />
    } else
        return (
            <>
                <AnuloTerminin data={dataToModal} getUpdatedData={getData} userID={props?.user_id} isOpen={isOpen} handleAddClose={() => { setIsOpen(false); setDataToModal(null) }} />
                <div className="testing">
                    {/* <div className={open ? "fromTo" : "from2"} > */}
                    <div className="refresh"><i onClick={() => setOpen(!open)} /></div>
                    {isPrevious ? <Search data={previousAppoinements} filtered={setFilteredPrev} setPageNumber={setPageNumber1} /> : <Search data={appointments} filtered={setFiltered} setPageNumber={setPageNumber} />}
                    {/* </div> */}
                </div>
                <div className='terminet' style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "79%" : "100%" }}>
                    <div className='titleTerminet' style={{ left: props?.menu ? '20%' : '0' }}>
                        <h3 class="titujt">Terminet</h3>
                    </div>
                    <div className='terminetNav' style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                        <button className={isPrevious === false && 'active'} onClick={() => setPrevious(false)}>Në të ardhmen</button>
                        <button className={isPrevious === true && 'active'} onClick={() => setPrevious(true)}>Të kaluarat</button>
                    </div>
                    <div className="terminetPosition" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                        {isPrevious === false && <>
                            {appointments && appointments?.length === 0 && <div className='text-secondary  m-3'><h6>Nuk ka shenime</h6></div>}
                            {appointments && appointments?.length > 0 && <>
                                <div className="row terminetLista">
                                    {
                                        displayFilteredData = filtered?.slice
                                            (pagesVisited, pagesVisited + productsPerPage)?.map
                                            (filtered => {
                                                let date = filtered.FromDate.slice(0, 10);
                                                let koha = filtered.FromDate.slice(11, 16);
                                                let kohaE = filtered.ToDate.slice(11, 16);

                                                const dita = moment(filtered.FromDate).format("dddd");
                                                const muaji = moment(filtered.FromDate).format("MMMM");
                                                const event = {
                                                    title: filtered.DocName,
                                                    startTime: filtered.FromDate.slice(0, 10) + " " + filtered.FromDate.slice(11, 16),
                                                    endTime: filtered.ToDate.slice(0, 10) + " " + filtered.ToDate.slice(11, 16),
                                                    description: filtered.Pershkrimi,
                                                    location: filtered.CustomerName,
                                                };


                                                return (
                                                    <div class="shfaqjet-listimi col-lg-6 col-md-6 col-12
                                                    " style={{ width: props?.menu ? "100%" : "80%" }}>


                                                        <div className="row">

                                                            <div class='data col-md-3 col-3 col-12 '>
                                                                <div class='row p-2 border-bottom text-center justify-content-center'>{dita}</div>
                                                                <div class='row p-2 border-bottom justify-content-center'><h2>{date.slice(8, 10)} </h2> </div>
                                                                <div class='row p-2 justify-content-center '>{muaji}</div>
                                                            </div>
                                                            <div class='infot col-md-9  col-9 col-12'>
                                                                <div class='row'>
                                                                    <h4 class='ora text-light'>{koha}</h4>
                                                                    <h4 class='shfaqjet-titulli'>{filtered.DocName}</h4>
                                                                    <h4 class='viti'>

                                                                        <ICalendarLink event={event}>
                                                                            <i class='fa fa-calendar text-light' />
                                                                        </ICalendarLink></h4>
                                                                    {
                                                                        filtered.StatusDCS !== "Anuluar" ? <h4 className='anulo'>
                                                                            <button className="btn btnAnulo" onClick={() => { handleAnuloTerminin(filtered) }} >Anulo</button>
                                                                        </h4> : ""
                                                                    }

                                                                </div>
                                                                <div class='row infotData '>
                                                                    <div class='col-md-7 col-12 text-left '><h6 className="font-weight-bold"> Institucioni:<span class='aktorja'>{filtered.CustomerName}</span></h6></div>
                                                                    <div class='col-md-5 col-12 text-left'><h6 className="font-weight-bold">Deri ne: <span class='aktorja'>{kohaE}</span></h6></div>
                                                                </div>
                                                                <div class='row'>

                                                                    {/* <h6 className="col-lg-2 col-md-4 text-left test font-weight-bold">Pershkrimi: </h6>
                                                            <h6 class='col-lg-10  col-md-8 text-left aktorja '>{filtered.Pershkrimi}</h6> */}
                                                                    <div class='col-12 text-left '><h6 className="font-weight-bold"> Pershkrimi:<span class='pl-2'>{filtered.Pershkrimi}</span></h6></div>
                                                                    <div class='col-md-6 text-left '><h6 className="font-weight-bold"> Status:<span class='pl-2'>{filtered.StatusDCS}</span></h6></div>

                                                                </div>
                                                                {/* <div><a href='<?php the_permalink()?>'> Sinposis </a></div> */}
                                                            </div>
                                                        </div>



                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </>}
                        </>}
                        {isPrevious === true && <>
                            {previousAppoinements && previousAppoinements?.length === 0 && <div className='text-secondary  m-3'><h6>Nuk ka shenime</h6></div>}
                            {previousAppoinements && previousAppoinements?.length > 0 && <>
                                <div className="row terminetLista">
                                    {displayFilteredData = filteredPrev?.slice
                                        (pagesVisited1, pagesVisited1 + productsPerPage)?.map
                                        (filtered => {
                                            let date = filtered.FromDate.slice(0, 10);
                                            let koha = filtered.FromDate.slice(11, 16);
                                            let kohaE = filtered.ToDate.slice(11, 16);
                                            const dita = moment(filtered.FromDate).format("dddd");
                                            const muaji = moment(filtered.FromDate).format("MMMM");
                                            console.log("From111", filtered.FromDate.slice(0, 10) + " " + filtered.FromDate.slice(11, 16));
                                            console.log("To", filtered.ToDate.slice(0, 10) + " " + filtered.ToDate.slice(11, 16));
                                            // console.log("To", filtered.ToDate)
                                            const event = {
                                                title: filtered.DocName,
                                                startTime: filtered.FromDate.slice(0, 10) + " " + filtered.FromDate.slice(11, 16),
                                                endTime: filtered.ToDate.slice(0, 10) + " " + filtered.ToDate.slice(11, 16),
                                                description: filtered.Pershkrimi,
                                                location: filtered.CustomerName,
                                            };

                                            return (
                                                <div class="shfaqjet-listimi col-lg-6 col-md-6 col-12
                                            " style={{ width: props?.menu ? "100%" : "80%" }}>


                                                    <div className="row">

                                                        <div class='data col-md-3 col-3 col-12'>
                                                            <div class='row p-2 border-bottom text-center justify-content-center'>{dita}</div>
                                                            <div class='row p-2 border-bottom justify-content-center'><h2>{date.slice(8, 10)} </h2> </div>
                                                            <div class='row p-2 justify-content-center '>{muaji}</div>
                                                        </div>
                                                        <div class='infot col-md-9  col-9 col-12'>
                                                            <div class='row'>
                                                                <h4 class='ora text-light'>{koha}</h4>
                                                                <h4 class='shfaqjet-titulli'>{filtered.DocName}</h4>
                                                                <h4 class='viti'>
                                                                    <ICalendarLink event={event}>
                                                                        <i class='fa fa-calendar text-light' />
                                                                    </ICalendarLink>
                                                                </h4>
                                                            </div>
                                                            <div class='row infotData'>
                                                                <div class='col-md-7 col-12 text-left '><h6 className="font-weight-bold"> Institucioni:<span class='aktorja'>{filtered.CustomerName}</span></h6></div>
                                                                <div class='col-md-5 col-12 text-left'><h6 className="font-weight-bold">Deri ne: <span class='aktorja'>{kohaE}</span></h6></div>
                                                                {/* <button className="text-danger" onClick={() => render(<CancelAppointment user_id={props?.user_id} customer_id={props?.customer_id} />)} >4444444</button> */}
                                                            </div>
                                                            <div class='row'>

                                                                {/* <h6 className="col-lg-2 col-md-4 text-left test font-weight-bold">Pershkrimi: </h6>
                                                            <h6 class='col-lg-10  col-md-8 text-left aktorja '>{filtered.Pershkrimi}</h6> */}
                                                                <div class='col-12 text-left '><h6 className="font-weight-bold"> Pershkrimi:<span class='pl-2'>{filtered.Pershkrimi}</span></h6></div>
                                                                <div class='col-12 text-left '><h6 className="font-weight-bold"> Status:<span class='pl-2'>{filtered.StatusDCS}</span></h6></div>

                                                            </div>
                                                            {/* <div><a href='<?php the_permalink()?>'> Sinposis </a></div> */}
                                                        </div>
                                                    </div>



                                                </div>
                                            )
                                        })
                                    }


                                </div>
                            </>}
                        </>}

                        <div className="paginationn mt-5 mb-5">
                        </div>
                        <div className="paginationWrapperPatien mb-5 text-right" key={resetComponent}>
                            {isPrevious ? ((previousAppoinements?.length >= productsPerPage) ?
                                <ReactPaginate
                                    previousLabel={<i className='fa fa-angle-double-left' />}
                                    nextLabel={<i className='fa fa-angle-double-right' />}
                                    pageCount={pageCountPrev}
                                    onPageChange={changePage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}

                                /> : ''
                            ) : ((appointments?.length >= productsPerPage) ?
                                <ReactPaginate
                                    previousLabel={<i className='fa fa-angle-double-left' />}
                                    nextLabel={<i className='fa fa-angle-double-right' />}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}

                                /> : ''
                            )}
                        </div>
                    </div>
                </div>

            </>
        )

}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu())
        // loadingOff: () => dispatch(loadingOff()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Terminet);
// export default Bashkpuntoret;