import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import "./orariPunes.scss";
import axios from '../../../axios';
import { connect } from "react-redux";
import {
    loggMeIN,
    rmAuth,
    toggleMenu,
    setCustomerId
} from "../../../redux/Functions/actions";
import moment from "moment";
import DitetModal from "./ditetModal";
import { render } from '@testing-library/react';
import EditCalendar from "../Calendar/editCalendar";
import EditModalKartel from "../ShtoKartelenEklientit/editKartelen";
import Loader from "../Loader/Loader";
import { TimePicker } from "antd";
import ModalPortal from "../../../portal/ModalPortal";

import "antd/dist/antd.css";


const OrariPunes = (props) => {

    const { pid } = useParams();
    console.log("Pid", pid)


    const navigate = useNavigate();
    const [awailableOnline, setAwailableOnline] = useState();
    const [disabled, setDisabled] = useState(false);
    const [hours, setHours] = useState([])
    const [loading, setLoading] = useState(true);
    const [dataPerDitet, setDataPerDitet] = useState();
    const [isOpen, setIsOpen] = useState(false);


    const format = "HH:mm";

    const AwailableOnline = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.AwailableOnline = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };

    const BookingOnMonday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnMonday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const BookingOnTuesday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnTuesday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const BookingOnWednesday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnWednesday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const BookingOnThursday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnThursday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const BookingOnFriday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnFriday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const BookingOnSaturday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnSaturday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };
    const BookingOnSunday = (e) => {
        const ud = Object.assign({}, awailableOnline);
        ud.BookingOnSunday = e.currentTarget.checked === true ? 1 : 0;
        setAwailableOnline(ud);
        setDisabled(true);

    };

    const getAllHours = () => {
        if (props?.role === 10) {

            axios
                // .get(`/admin/getCustomerDoctors/${props?.customer_id}`)
                .get(`customer/appointments/getCustomerUserHoursDays/${pid}`)
                .then((response) => {
                    setHours(response?.data);
                    setLoading(false);

                    console.log("setHours", response?.data);

                })

                .catch((err) => console.log("error", err));
        }
        else if (props?.role === 2) {
            axios
                // .get(`/admin/getCustomerDoctors/${props?.customer_id}`)
                .get(`customerUser/appointments/getCustomerUserHoursDays/${pid}`)
                .then((response) => {
                    setHours(response?.data);
                    setLoading(false);

                    console.log("setHours", response?.data);

                })

                .catch((err) => console.log("error", err));
        }
        else if (props?.role === 3) {
            axios
                // .get(`/admin/getCustomerDoctors/${props?.customer_id}`)
                .get(`receptionist/appointments/getCustomerUserHoursDays/${pid}`)
                .then((response) => {
                    setHours(response?.data);
                    setLoading(false);
                    console.log("setHours", response?.data);

                })

                .catch((err) => console.log("error", err));
        }
    };
    const handleDitetModal = (data) => {
        setDataPerDitet(data);
        setIsOpen(true);
        console.log("ditett", data)
    }

    useEffect(() => {
        getAllHours();
    }, []);

    if (loading === true) {
        return <Loader />
    } else
        return (
            <>
                <DitetModal dataToEdit={dataPerDitet} pid={pid} role={props?.role} getAllOret={getAllHours} isOpen={isOpen} handleAddClose={() => { setIsOpen(false); setDataPerDitet(null) }} />

                <div className="testing">
                    <div className="">
                        <i className="goBack" aria-hidden="true" onClick={() => navigate(-1)} style={{ left: props?.menu ? '23%' : '6%' }} />
                    </div>
                </div>
                <div className="orariPunes" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>


                    <h3 className="titujt">Orari i Punes</h3>
                    <div class="table-responsive" >
                        <div className="orariPunesStatic" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "80%" : "100%" }}>
                            <table class="table"  >
                                <thead>
                                    <tr className="list-item-head-new">
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Dita</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Punuese</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Orari punes</th>
                                        <th className="col-md-2 col-4 text-left pl-4 tableContent">Pauza</th>
                                        <th className="col-md-2 col-4 text-center pl-4 tableContent">Ndrysho</th>



                                        {/* <th className="col-md-2 col-4">Download</th> */}

                                        {/* <div className='text-start col-3 '>Customer Name</div>
                                    <div className='text-start col-3'>Card Number</div>
                                    <div className=' col-3 text-start'>Data e validimit</div>
                                    <div className=' col-3'>Validuar</div> */}

                                    </tr>
                                </thead>


                                {hours?.map((el, index) => {
                                    return (
                                        <>
                                            {/* <><DitetModal pid={pid} role={props?.role} orariPrej={el?.AvailableFrom} orariDeri={el?.AvailableTo} pauzaPrej={el?.PauzaFrom} pauzaDeri={el?.PauzaTo} dita={el?.DitaName} online={el?.AllowBooking} ditaNr={el?.DitaNr} getAllOret={getAllHours} /> */}

                                            <tr className="list-item" key={index}>
                                                <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{el?.DitaName} </td>
                                                <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">
                                                    <input disabled type="checkbox" id={"NotificationWithSMS"} className="switch col-2 ml-2" defaultChecked={el?.AllowBooking} />
                                                </td>
                                                {/* {filtered?.ValidatedOn?.length > 0 ? <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin text-start'>{(filtered?.ValidatedOn)?.slice(0, 10)}</td> : <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin text-start'></td>}
                                                {filtered?.Validated === false ? <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin  pl-5 col-3 '><i className='fa fa-times' /></td> : <td className='col-md-2 col-4 text-left pl-4 tableContentStafiAdmin pl-5 col-3'><i className='fa fa-check' /></td>} */}
                                                <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{el?.AvailableFrom.slice(11, 16)}-{el?.AvailableTo.slice(11, 16)}</td>
                                                <td className="col-md-2 col-4 text-left pl-4 tableContentStafiAdmin">{el?.PauzaFrom.slice(11, 16)}-{el?.PauzaTo.slice(11, 16)}</td>
                                                <td className="col-md-2 col-4 text-center pl-4 tableContentStafiAdmin">  <div onClick={() => { handleDitetModal(el) }}><i className="fa fa-pencil-square-o iconCursor" /></div></td>
                                                {console.log("piddddddddddd", pid)}
                                                {/* <div className="col-md-12 col-2 mt-1">
                                                        <div className="form-group">
                                                            <div className="btn btnLogin " onClick={() => render(<DitetModal pid={pid} orariPrej={el?.AvailableFrom} orariDeri={el?.AvailableTo} pauzaPrej={el?.PauzaFrom} pauzaDeri={el?.PauzaTo} dita={el?.DitaName} online={el?.AllowBooking} ditaNr={el?.DitaNr} getAllHours={getAllHours} />)}>Ndrysho</div>
                                                        </div>

                                                    </div> */}
                                            </tr>

                                        </>
                                    )
                                })}
                            </table>


                        </div>

                    </div>

                    {/* <div className="orariPunesStatic" style={{ left: props?.menu ? '20%' : '0', width: props?.menu ? "70%" : "80%" }}>
                    <div className="orariPunes-Form">
                        <form>
                            <div className="input-design text-left">
                                <div className="dita1 mb-3 mt-5">
                                    <div className="row">
                                        {hours?.map((el) => {
                                            return (
                                                <>
                                                    <div className="col-lg-2 col-6">
                                                        <p>Dita: <span className="ml-1">{el?.DitaName}</span></p>

                                                    </div>
                                                    <div className="col-lg-2 col-6">
                                                        <p>Online: <input disabled type="checkbox" id={"NotificationWithSMS"} className="switch col-2 ml-2" defaultChecked={el?.AllowBooking} /></p>



                                                    </div>
                                                    <div className="col-lg-3 col-6">
                                                        <p>Orari i punes: <span className="ml-2"> {el?.AvailableFrom.slice(11, 16)}-{el?.AvailableTo.slice(11, 16)}</span> </p>



                                                    </div>


                                                    <div className="col-lg-3 col-6">
                                                        <p>Pauza <span className="ml-2">{el?.PauzaFrom.slice(11, 16)}-{el?.PauzaTo.slice(11, 16)}</span></p>

                                                    </div>

                                                    <div className="col-lg-2 col-12">
                                                        <div className="form-group">
                                                            <div className="btn btnLogin " onClick={() => render(<DitetModal pid={pid} orariPrej={el?.AvailableFrom} orariDeri={el?.AvailableTo} pauzaPrej={el?.PauzaFrom} pauzaDeri={el?.PauzaTo} dita={el?.DitaName} online={el?.AllowBooking} ditaNr={el?.DitaNr} getAllHours={getAllHours} />)}>Ndrysho</div>
                                                        </div>

                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div >
                </div> */}
                </div >
            </>
        )
}
const mapStateToProps = state => {
    return {
        lang: state.data.lang,
        isLoading: state.data.isLoading,
        loggedIn: state.data.loggedIn,
        menu: state.data.menu,
        user_id: state.data.user_id,
        customer_id: state.data.customer_id,
        role: state.data.role,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loggMeIN: () => dispatch(loggMeIN()),
        rmAuth: () => dispatch(rmAuth()),
        toggleMenu: () => dispatch(toggleMenu()),
        // loadingOff: () => dispatch(loadingOff()),
        setCustomerId: (customer_id) => dispatch(setCustomerId(customer_id)),
        // setRole: () => dispatch(setRole()),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrariPunes);

